import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { addEntity, removeEntity, setAllEntities, setEntity, updateEntity } from "@ngrx/signals/entities";
import { inject } from "@angular/core";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { tapResponse } from "@ngrx/operators";
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, pipe, tap } from "rxjs";
import { Router } from "@angular/router";
import { ApiService } from "../../../../../api/services";
import { DataCacheDbController } from "../../../../script/data-cache-db.controller";
import { TDataCacheDB } from "../../../../script/data-cache-db.types";
import {
    ScenarioCreateRequest,
    ScenarioResponse,
    SmartButtonsCreateRequest,
    SmartButtonsResponse
} from "../../../../../api/models";

export function withSsmartButtonsApi() {
    return signalStoreFeature(
        withMethods((store: any,
            apiService: ApiService = inject(ApiService),
        ) => ({
            loadSmartButtons: rxMethod<void>(
                pipe(
                    exhaustMap(() => {
                        let dataCacheDbController = new DataCacheDbController();
                        dataCacheDbController.readDb('apiSmartButtons').then((obj: TDataCacheDB) => {
                            if (obj?.id && obj.id === 'apiSmartButtons') {
                                patchState(store, setAllEntities(JSON.parse(obj.text)));
                                patchState(store, { isLoaded: true });
                            }
                        });
                        return apiService.apiSmartButtonsGet().pipe(
                            tap({
                                next: (smartButtons: SmartButtonsResponse[]) => {
                                    dataCacheDbController.writeDb(smartButtons, 'apiSmartButtons');
                                    patchState(store, setAllEntities(smartButtons));
                                    patchState(store, { isLoaded: true });
                                },
                                error: console.error,
                            })
                        );
                    })
                ),
            ),
            createSmartButton: rxMethod<SmartButtonsCreateRequest>(
                pipe(filter((smartButton: SmartButtonsCreateRequest) => !!smartButton), exhaustMap((smartButton: SmartButtonsCreateRequest) => {
                    return apiService.apiSmartButtonsPost({ body: smartButton }).pipe(
                        tapResponse({
                            next: () => {},
                            error: console.error
                        })
                    )
                }))
            ),
            deleteSmartButton: rxMethod<string>(
                pipe(filter((smartButtonId: string) => !!smartButtonId), exhaustMap((smartButtonId: string) => {
                    return apiService.apiSmartButtonsIdDelete({ id: smartButtonId }).pipe(
                        tapResponse({
                            next: () => {},
                            error: console.error
                        })
                    )
                }))
            ),
        })),
    )
}
