import {patchState, signalStore, withComputed, withMethods, withState} from "@ngrx/signals";
import {addEntity, removeEntity, withEntities} from "@ngrx/signals/entities";
import { withSsmartButtonsApi } from "./smart-buttons-api.feature";
import {SmartButtonsResponse} from "../../../../../api/models";
type TSmartButtonsState = {
    isLoaded: boolean,
};

export const initialSmartButtonsState: TSmartButtonsState = {
    isLoaded: false,
};

export const SmartButtonsStore = signalStore(
    { providedIn: "root", protectedState: false },
    withState(initialSmartButtonsState),
    withEntities<SmartButtonsResponse>(),
    withSsmartButtonsApi(),
    withMethods((store: any) => ({
        addSmartButton: (button: SmartButtonsResponse) => {
            patchState(store, addEntity(button));
        },
        removeSmartButtonById: (id: string) => {
            patchState(store, removeEntity(id));
        },
    })),
    withComputed((store) => ({

    })),
)
